/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {


    $('.js-menu').on('click',(e)=>{
      this.megaMenu(e.currentTarget);
    });
    $('.js-siteMaplist1').on('click',(e)=>{
      this.siteMaplist1(e.currentTarget)
    })
    $('.js-siteMaplist2').on('click',(e)=>{
      this.siteMaplist2(e.currentTarget)
    })

    // $(window).on('load resize',()=>{
    //   this.resizeSiteMap()
    // })


    //黒い背景をクリックするとメニューが閉じる
    $('.js-bg').on('click',(e)=>{
      $('.l-header__sitemapBg').removeClass('is-active');
      $("html").removeClass("is-fixed");
      $(".js-menu").removeClass('is-active');
      $('.l-header__sitemap').slideUp(250);
    });

  }
  //ハンバーガーメニュー
  megaMenu(el){
    if($(el).hasClass('is-active')){
      $(el).removeClass('is-active');
      $('.l-header__sitemap').slideUp(250);
      $('.l-header__sitemapBg').removeClass('is-active');
      $("html").removeClass("is-fixed");
    }else{
      $(el).addClass('is-active');
      $('.l-header__sitemap').slideDown(250);
      $('.l-header__sitemapBg').addClass('is-active');
      $("html").addClass("is-fixed");
    }
  }
  //アコーディオン１
  siteMaplist1(el){
    if($(el).hasClass('is-active')){
      $(el).removeClass('is-active');
      $(el).parents('.l-header__sitemapListItem').find(".l-header__accordion1").slideUp(250);
      $(el).parents('.l-footer__sitemapListItem').find(".l-footer__accordion1").slideUp(250);
    }else{
      $(el).addClass('is-active');
      $(el).parents('.l-header__sitemapListItem').find(".l-header__accordion1").slideDown(250);
      $(el).parents('.l-footer__sitemapListItem').find(".l-footer__accordion1").slideDown(250);
    }
  }
  //アコーディオン２
  siteMaplist2(el){
    if($(el).hasClass('is-active')){
      $(el).removeClass('is-active');
      $(el).parents('.l-header__sitemapTxt').find(".l-header__accordion2").slideUp(250);
      $(el).parents('.l-footer__sitemapTxt').find(".l-footer__accordion2").slideUp(250);
    }else{
      $(el).addClass('is-active');
      $(el).parents('.l-header__sitemapTxt').find(".l-header__accordion2").slideDown(250);
      $(el).parents('.l-footer__sitemapTxt').find(".l-footer__accordion2").slideDown(250);
    }
  }



  // resizeSiteMap(){
  //   let ww = window.innerWidth;
  //   const bp = 750;
  //   console.log(ww)
  //   if(ww > bp){
  //     $('.js-siteMaplist').removeClass('is-active');
  //     $('.l-footer__sitemapListItem').find('ul').removeAttr('style');
  //     $('.l-header__sitemapListItem').find('ul').removeAttr('style');
  //   }
  // }
}

export default Common;
