/**
 * コモンクラス
 * @constructor
 */
class Category {
  constructor() {

  }
  /**
   * コモン
   */
  init() {

    $('.js-categoryList').on('click',(e)=>{
      this.siteMaplist2(e.currentTarget)
    })

    // $(window).on('load resize',()=>{
    //   this.resizeSiteMap()
    // })

  }
  //アコーディオン
  siteMaplist2(el){
    if($(el).hasClass('is-active')){
      $(el).removeClass('is-active');
      $(el).parents('.js-accordionBtn').find(".js-accordionPanel").slideUp(250);
    }else{
      $(el).addClass('is-active');
      $(el).parents('.js-accordionBtn').find(".js-accordionPanel").slideDown(250);
    }
  }


}

export default Category;
