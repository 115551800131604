import Swiper from 'swiper';

class TopSlide {
  constructor() {
    this.myswiper = null;
  }
  init() {
    if($('div').hasClass('js-topKvSlide')){
      this.SwiperInit();
      this.swiperEvents();
    }
  }



  SwiperInit(){
    //サムネイル
    var sliderThumbnail = new Swiper('.slider-thumbnail', {
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    //スライダー
    this.slider = new Swiper('.swiper-container-top', {
      loop: true,
      effect:'fade',
      speed: 600, //２秒かけながら次の画像へ移動
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      on:{
        init: function () {
          $('.js-Thumb').eq(0).addClass('is-active');
        },
      },
      thumbs: {
        swiper: sliderThumbnail
      }

    });

  }
  swiperEvents(){
    this.slider.on('slideChange',function(){
      $('.js-Thumb').removeClass('is-active');
      let number = this.activeIndex-1;
      if(number > $('.js-Thumb').length -1){
        number = 0
      }

      //console.log(number);
      $('.js-Thumb').eq(number).addClass('is-active');
    })

  }


};

export default TopSlide;
