/**
 * コモンクラス
 * @constructor
 */
class ToTop {
  constructor() {

  }
  /**
   * TOPへ戻る
   */
  init() {


    $(window).on("scroll", function() {
      if ($(this).scrollTop() > 300) {
        $(".js-toTop").fadeIn();
      } else {
        $(".js-toTop").fadeOut();
      }
    });

  }
}

export default ToTop;
