/**
 * コモンクラス
 * @constructor
 */
class Modal {
  constructor() {

  }
  /**
   * TOPへ戻る
   */
  init() {
    const buttonOpen = document.getElementById('modalOpen');
    const modal = document.getElementById('easyModal');
    const buttonClose = document.getElementsByClassName('modal__close')[0];

    //ボタンがクリックされた時
    buttonOpen.addEventListener('click', modalOpen);
      function modalOpen() {
      modal.style.display = 'block';
      $("html").addClass("is-fixed");
    };

    //バツ印がクリックされた時
    buttonClose.addEventListener('click', modalClose);
      function modalClose() {
      modal.style.display = 'none';
      $("html").removeClass("is-fixed");
    };

    //モーダルコンテンツ以外がクリックされた時
    addEventListener('click', outsideClose);
    function outsideClose(e) {
      if (e.target == modal) {
        modal.style.display = 'none';
        $("html").removeClass("is-fixed");
      };
};


  }
}

export default Modal;
