import Swiper from 'swiper';

class PickupSlide {
  init() {
    const mySwiper = new Swiper('.js-pickupSlider', {
      spaceBetween: 30,
      slidesPerView: 1,
      breakpoints: {
        // 750px以上の場合
        750: {
          slidesPerView: 3,
        },
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }
};

export default PickupSlide;
