/**
 * コモンクラス
 * @constructor
 */
class tab {
  constructor() {

  }
  /**
   * コモン
   */
  init() {



    $('.js-tab .js-btn').on('click', function(){
      var index = $('.js-tab .js-btn').index(this);
      $('.js-tab .js-btn, .js-tab .js-panel').removeClass('active');
      $(this).addClass('active');
      $('.js-tab .js-panel').eq(index).addClass('active');
    });


  }


}

export default tab;
